import React from "react";
import { useState, useEffect } from "react";
import { redirect, useNavigate } from "react-router-dom";

// Firestore Database
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// CSS
import "./css/Home.css";
import SendingPage from "./SendingPage";

// Auth Stuff
import { UserAuth } from "../Context/AuthContext";

// Components
import ScrapedResultsList from "../Components/ScrapedResultsList";

// import icons
import dashboard_icon from "../images/dashboard.png";
import scrape_icon from "../images/scrape.png";
import send_icon from "../images/send.png";
import logo_icon from "../images/logo.png";
import company_list_icon from "../images/company_list.png";
import Companies from "./Companies";
import default_profile from "../images/default-profile.png";

const Home = () => {
  // _____________________________________ Auth (Nick) _________________________________
  const { user, logout } = UserAuth();

  const navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await logout();
      navigate("/");
    } catch (e) {
      console.log(e.message);
    }
  };
  // for jame's push

  // _____________________________________ Home Components (James) _________________________________
  // const [active, setActive] = useState("Send");
  const [active, setActive] = useState("Dashboard");
  const [scrapedData, setScrapedData] = useState([]);
  const [scrapedDatasetNames, setScrapedDatasetNames] = useState([]);
  const [datasets_sent, setDatasetsSent] = useState([]);
  const [profileImage, setProfileImage] = useState(null);
  const [isSidebarExpanded, setSidebarExpanded] = useState(false);

  useEffect(() => {
    if (user && user.uid) {
      const fetchScrapedData = async () => {
        console.log(user.uid);
        try {
          const response = await fetch(
            `https://api.thenodes.ca/get-user-scraped-results?userID=${user.uid}`,
            // `http://127.0.0.1:8000/get-user-scraped-results?userID=${user.uid}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
          const data = await response.json();

          if (data.success) {
            setScrapedData(data.data);
            const dataMap = new Map(Object.entries(data.data));
            const dataset_names = Array.from(dataMap.keys());
            setScrapedDatasetNames(dataset_names);
          } else {
            console.log("Error fetching scraped data");
          }
        } catch (error) {
          console.log(error);
        }
      };

      // fetch datasets sent
      const fetchDatasetsSent = async () => {
        try {
          const response = await fetch(
            // `http://127.0.0.1:8000/get-user-sent-datasets?userID=${user.uid}`,
            `https://api.thenodes.ca/get-user-sent-datasets?userID=${user.uid}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
              },
            }
          );

          const data = await response.json();
          console.log(data);

          if (data.success) {
            setDatasetsSent(data.data);
          } else {
            console.log("Error fetching datasets sent");
          }
        } catch (error) {
          console.log(error);
        }
      };

      fetchScrapedData();
      fetchDatasetsSent();
    }
  }, [user]);

  useEffect(() => {
    if (user && user.photoURL) {
      const img = new Image();
      img.onload = () => setProfileImage(user.photoURL);
      img.onerror = () => setProfileImage(default_profile);
      img.src = user.photoURL;
    } else {
      setProfileImage(default_profile);
    }
  }, [user]);

  const MenuButton = ({ icon, name }) => {
    const handleButtonClick = async (e) => {
      e.preventDefault();
      if (name === "Scrape") {
        window.open('https://bush-library-96c.notion.site/Nodes-user-guide-183753b70d49800697e5f3bccf745f18?pvs=73', '_blank');
        return;
      }
      setActive(name);
    };

    return (
      <button 
        className="MenuButton" 
        title={name === "Scrape" ? "Tutorial" : name} 
        onClick={handleButtonClick}
      >
        <div className="button-content">
          <img src={icon} alt={name} />
          <span className={`button-label ${isSidebarExpanded ? 'expanded' : ''}`}>
            {name === "Scrape" ? "Tutorial" : name}
          </span>
        </div>
      </button>
    );
  };

  const redirectHome = () => {
    navigate("/home");
  };

  const toggleDropdown = () => {
    const dropdown = document.getElementById("profile-dropdown");
    if (dropdown.style.display === "none" || dropdown.style.display === "") {
      dropdown.style.display = "block";
    } else {
      dropdown.style.display = "none";
    }
  };

  // __________________________________________________________________________

  return (
    <section id="Home">
      <div className="top">
        <img src={logo_icon} id="home-logo" onClick={redirectHome}></img>

        <div id="profile-icon" onClick={toggleDropdown}>
          <img 
            src={profileImage || default_profile} 
            alt="profile"
            onError={(e) => {
              e.target.src = default_profile;
              e.target.onerror = null;
            }}
          />

          <div id="profile-dropdown">
            <button onClick={handleLogout} id="logout-btn">
              Logout
            </button>
          </div>
        </div>
      </div>

      <div className="content">
        <div className={`menu ${isSidebarExpanded ? 'expanded' : ''}`}>
          <button 
            className="toggle-sidebar"
            onClick={() => setSidebarExpanded(!isSidebarExpanded)}
          >
            {isSidebarExpanded ? '◀' : '▶'}
          </button>
          <MenuButton icon={dashboard_icon} name="Dashboard" />
          <MenuButton icon={company_list_icon} name="Companies" />
          <MenuButton icon={scrape_icon} name="Scrape" />
          <MenuButton icon={send_icon} name="Send" />
        </div>
        <div className="container">
          {active === "Dashboard" && (
            <ScrapedResultsList scrapedData={scrapedData} />
          )}
          {active === "Companies" && <Companies inHome={true} />}
          {active === "Scrape" && <div>Scrape</div>}
          {active === "Send" && (
            <SendingPage
              scrapedData={scrapedData}
              datasets_sent={datasets_sent}
              scrapedDatasetNames={["None", ...scrapedDatasetNames]}
            />
          )}
        </div>
      </div>

    </section>
  ); // comment
};

export default Home;