import React, { useState } from "react";
import "./css/SendEmails.css";
import sendImage from "../images/send.png";
import questionMark from "../images/help-icon.png";

const SendEmails = ({
  setMessage,
  setSubject,
  subject,
  message,
  appCode,
  setAppCode,
  handleSendEmails,
}) => {
  const [errors, setErrors] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleSubjectChange = (event) => {
    setSubject(event.target.value);
  };

  const handleAppCodeChange = (event) => {
    setAppCode(event.target.value);
  };

  const validateAndSend = () => {
    const newErrors = {};
    if (!subject.trim()) newErrors.subject = "Subject cannot be empty";
    if (!message.trim()) newErrors.message = "Message cannot be empty";
    if (!appCode.trim())
      newErrors.appCode = "App Code cannot be empty. Learn how to get it: ";

    setErrors(newErrors);

    if (Object.keys(newErrors).length === 0) {
      handleSendEmails();
    } else {
      Object.keys(newErrors).forEach((field) => {
        const element = document.querySelector(`.${field}`);
        if (element) {
          element.classList.add("shake");
          setTimeout(() => element.classList.remove("shake"), 500);
        }
      });
    }
  };

  const openAppCodeVideo = () => {
    window.open("https://bush-library-96c.notion.site/Get-App-Code-183753b70d4980f7a334e87906a640eb?pvs=4", "_blank");
  };

  return (
    <div className="send-emails-container">
      <input
        type="text"
        name="email-subject"
        value={subject}
        onChange={handleSubjectChange}
        placeholder="subject:"
        className={`subject ${errors.subject ? "error" : ""}`}
        autoComplete="off"
      />
      {errors.subject && <div className="error-message">{errors.subject}</div>}

      <textarea
        value={message}
        onChange={handleMessageChange}
        placeholder="message: (Use [name] for recipient's name and [company] for their company)"
        className={`email-area ${errors.message ? "error" : ""}`}
      />
      {errors.message && <div className="error-message">{errors.message}</div>}
      <div className="placeholder-info">
        Use [name] for recipient's name and [company] for their company in your
        message.
      </div>

      <div className="app-code-container">
        <input
          type="password"
          name="email-app-code"
          value={appCode}
          onChange={handleAppCodeChange}
          placeholder="App Code:"
          className={`app-code ${errors.appCode ? "error" : ""}`}
          autoComplete="new-password"
        />
        <div className="tooltip-container">
          <button
            className="question-mark-btn"
            onClick={openAppCodeVideo}
            onMouseEnter={() => setShowTooltip(true)}
            onMouseLeave={() => setShowTooltip(false)}
          >
            <img src={questionMark} alt="Help" />
          </button>
          {showTooltip && <div className="tooltip">Guide to get App Code</div>}
        </div>
      </div>
      {errors.appCode && (
        <div className="error-message">
          {errors.appCode}
          <a
            href="https://bush-library-96c.notion.site/Get-App-Code-183753b70d4980f7a334e87906a640eb?pvs=4"
            target="_blank"
            rel="noopener noreferrer"
          >
            Watch this video
          </a>
        </div>
      )}

      <button className="send-btn" onClick={validateAndSend}>
        <img src={sendImage} alt="send" />
      </button>
    </div>
  );
};

export default SendEmails;
