import React, { useState, useContext, createContext, useEffect } from "react";
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  GoogleAuthProvider,
  signInWithPopup,
} from "firebase/auth";
import { auth, firestore } from "../firebase.js";
import { doc, getDoc, setDoc } from "firebase/firestore";

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});

  const createUser = async (email, password) => {
    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log("New user UserID: ", user.uid);

      await setDoc(doc(firestore, "Users", user.uid), {
        email: email,
        scraped_results: {},
        emails_sent: [],
        premium: false,
        userID: user.uid,
      });
      console.log("Document written with ID: ", user.uid);

      return true;
    } catch (error) {
      const errorMessage = error.message;
      console.log("Error creating user: ", errorMessage);
      return errorMessage;
    }
  };

  const signIn = async (email, password) => {
    try {
      console.log(email, password);
      const userCredential = await signInWithEmailAndPassword(
        auth,
        email,
        password
      );
      const user = userCredential.user;
      console.log("UserID at signin: ", user.uid);

      const userRef = doc(firestore, "Users", user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        console.log("User not found in firestore db");
        await setDoc(userRef, {
          email: email,
          scraped_results: {},
          emails_sent: [],
          premium: false,
          userID: user.uid,
          password: password,
        });
        console.log("User was added in firestoredb with ID: ", user.uid);
      }

      return true;
    } catch (error) {
      const errorMessage = error.message;
      console.log("Error signing in user: ", errorMessage);
      return errorMessage;
    }
  };

  const googleSignIn = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      console.log("Google Sign In UserID: ", user.uid);

      const userRef = doc(firestore, "Users", user.uid);
      const userSnap = await getDoc(userRef);

      if (!userSnap.exists()) {
        await setDoc(userRef, {
          email: user.email,
          scraped_results: {},
          emails_sent: [],
          premium: false,
          userID: user.uid,
        });
        console.log("New Google user added to Firestore with ID: ", user.uid);
      } else {
        console.log("Existing Google user signed in: ", user.uid);
      }

      return true;
    } catch (error) {
      const errorMessage = error.message;
      console.log("Error signing in with Google: ", errorMessage);
      return errorMessage;
    }
  };

  const logout = () => {
    return signOut(auth);
  };

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      console.log(currentUser);
      
      if (currentUser) localStorage.setItem("user", JSON.stringify(currentUser));
      else localStorage.setItem("user", null);

      setUser(currentUser);
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{ createUser, user, logout, signIn, googleSignIn }}
    >
      {children}
    </UserContext.Provider>
  );
};

export const UserAuth = () => {
  return useContext(UserContext);
};
