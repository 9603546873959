import React, { useState, useEffect } from "react";
import Dropdown from "../Components/SelectedDataDropdown";
import { UserAuth } from "../Context/AuthContext";
import SendEmails from "./SendEmails";
import "./css/SendingPage.css";
import RangeSelector from '../Components/RangeSelector';

import defaultImg from "../images/default-profile.png";

const ResultEntry = ({ name, company, profile_link, profile_img, onRemove }) => {
  return (
    <div className="result-entry">
      <button 
        className="remove-button"
        onClick={onRemove}
        title="Remove from list"
      >
        ✕
      </button>
      <a
        className="result-info name"
        title={name}
        href={profile_link}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img
          className="profile-img"
          src={profile_img ? profile_img : defaultImg}
          alt="profile"
        ></img>
        <div>{name}</div>
      </a>
      <div className="result-info company">@{company}</div>
    </div>
  );
};

const formatDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleString('en-US', { 
    month: 'numeric',
    day: 'numeric',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true
  });
};

const SentRangesWarning = ({ overlappingRanges, isDatasetComplete }) => {
  if (isDatasetComplete) {
    return (
      <div className="warning-inline dataset-complete">
        <span className="warning-icon">✅</span>
        <div className="warning-text">
          All emails in this dataset have been sent
        </div>
      </div>
    );
  }

  if (!overlappingRanges?.length) return null;
  
  // Sort ranges by start index
  const sortedRanges = [...overlappingRanges].sort((a, b) => a.range_start - b.range_start);
  
  return (
    <div className="warning-inline">
      <span className="warning-icon">⚠️</span>
      <div className="warning-text">
        Previously sent to: 
        {sortedRanges.map((range, idx) => (
          <span key={idx} className="warning-range">
            {range.range_start + 1}-{range.range_end + 1}
            <span className="warning-date">{formatDate(range.date_sent)}</span>
            {idx < sortedRanges.length - 1 && <span className="warning-separator">•</span>}
          </span>
        ))}
      </div>
    </div>
  );
};

const PreviouslySentRanges = ({ sentRanges }) => {
  if (!sentRanges?.length) return null;
  
  return (
    <div className="previously-sent-container">
      <h4>Previously Sent Ranges:</h4>
      <div className="sent-ranges">
        {sentRanges.map((range, idx) => (
          <div key={idx} className="sent-range-item">
            <span className="range">
              {range.range_start + 1}-{range.range_end + 1}
            </span>
            <span className="date">
              {formatDate(range.date_sent)}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

const SendingPage = ({ scrapedData, datasets_sent, scrapedDatasetNames }) => {
  const { user } = UserAuth();
  const [selectedOption, setSelectedOption] = useState("None");
  const [emailData, setEmailData] = useState([]);
  const [emailsSentSuccessfully, setEmailsSentSuccessfully] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [alreadySentWarning, setAlreadySentWarning] = useState(false);
  const [isDatasetComplete, setIsDatasetComplete] = useState(false);

  const [message, setMessage] = useState("");
  const [subject, setSubject] = useState("");
  const [appCode, setAppCode] = useState("");

  const [rangeStart, setRangeStart] = useState(0);
  const [rangeEnd, setRangeEnd] = useState(0);
  const [sentRanges, setSentRanges] = useState([]);

  const [removedIndices, setRemovedIndices] = useState(new Set());

  const [overlappingRanges, setOverlappingRanges] = useState([]);

  // Add function to check if dataset is complete
  const checkDatasetCompletion = (ranges, totalCount) => {
    if (!ranges?.length || !totalCount) return false;

    // Sort ranges by start index
    const sortedRanges = [...ranges].sort((a, b) => a.range_start - b.range_start);
    
    // Create a Set of all sent indices
    const sentIndices = new Set();
    sortedRanges.forEach(range => {
      for (let i = range.range_start; i <= range.range_end; i++) {
        sentIndices.add(i);
      }
    });

    // Check if all indices from 0 to totalCount-1 are in the set
    return sentIndices.size === totalCount;
  };

  const handleDropdownChange = (e) => {
    e.preventDefault();
    const datasetName = e.target.value;
    setSelectedOption(datasetName);

    // Reset all states when switching datasets
    setRangeStart(0);
    setRemovedIndices(new Set());
    setOverlappingRanges([]);
    setAlreadySentWarning(false);
    setIsDatasetComplete(false);
    
    const selectedDataset = scrapedData[datasetName];
    if (selectedDataset) {
      setEmailData(selectedDataset);
      setRangeEnd(selectedDataset.length);
      
      // Get sent ranges for this dataset
      const datasetInfo = datasets_sent.find(d => d.name === datasetName);
      if (datasetInfo) {
        setSentRanges(datasetInfo.sent_ranges);
        // Check if dataset is complete
        setIsDatasetComplete(checkDatasetCompletion(datasetInfo.sent_ranges, selectedDataset.length));
      } else {
        setSentRanges([]);
      }
    } else {
      setEmailData([]);
      setRangeEnd(0);
      setSentRanges([]);
    }
  };

  const handleRemoveRecipient = (originalIndex) => {
    setRemovedIndices(current => {
      const updated = new Set(current);
      updated.add(originalIndex);
      return updated;
    });
  };

  useEffect(() => {
    if (selectedOption && scrapedData) {
      const selectedDataset = scrapedData[selectedOption];
      if (selectedDataset) {
        setEmailData(selectedDataset);
      }
    }
  }, [selectedOption, scrapedData]);

  useEffect(() => {
    if (selectedOption && emailData.length > 0) {
      // Set end to total length since we're displaying 1-indexed values
      setRangeEnd(emailData.length);  // Changed from emailData.length - 1
      
      // Find sent ranges for this dataset
      const datasetInfo = datasets_sent.find(d => d.name === selectedOption);
      if (datasetInfo) {
        setSentRanges(datasetInfo.sent_ranges);
      }
    }
  }, [selectedOption, emailData]);

  useEffect(() => {
    if (selectedOption && selectedOption !== "None" && sentRanges.length > 0) {
      const overlapping = sentRanges.filter(range => 
        (rangeStart >= range.range_start && rangeStart <= range.range_end) ||
        (rangeEnd >= range.range_start && rangeEnd <= range.range_end)
      );
      
      setOverlappingRanges(overlapping);
      setAlreadySentWarning(overlapping.length > 0);
    } else {
      // Reset warning states if no dataset is selected
      setOverlappingRanges([]);
      setAlreadySentWarning(false);
    }
  }, [selectedOption, sentRanges, rangeStart, rangeEnd]);

  const handleSendEmails = async () => {
    setIsLoading(true);

    const visibleData = emailData
      .filter((_, index) => !removedIndices.has(index))
      .slice(rangeStart, rangeEnd + 1);

    const userEmailDetails = {
      userID: user.uid,
      filteredData: visibleData,
      message,
      subject,
      datasetName: selectedOption,
      app_code: appCode,
      range_start: rangeStart
    };

    try {
      // const response = await fetch("http://127.0.0.1:8000/send-emails", {
      const response = await fetch("https://api.thenodes.ca/send-emails", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(userEmailDetails),
      });
      const data = await response.json();
      console.log(data);

      if (data.status === "success") {
        setEmailsSentSuccessfully(true);
        
        // Show success message with refresh instruction
        const successMessage = document.createElement("div");
        successMessage.className = "success-message";
        const messageContent = document.createElement("div");
        messageContent.className = "success-content";
        
        const title = document.createElement("div");
        title.className = "success-title";
        title.textContent = "✅ Emails sent successfully!";
        
        const instruction = document.createElement("div");
        instruction.className = "success-instruction";
        instruction.textContent = "Please refresh the page and check your inbox.";
        
        messageContent.appendChild(title);
        messageContent.appendChild(instruction);
        successMessage.appendChild(messageContent);
        document.body.appendChild(successMessage);

        // Remove success message after 5 seconds
        setTimeout(() => {
          successMessage.classList.add("fade-out");
          setTimeout(() => {
            document.body.removeChild(successMessage);
          }, 500);
        }, 5000);
      } else {
        throw new Error(data.detail || "Failed to send emails");
      }
    } catch (e) {
      console.log(e);
      // Show error message
      const errorMessage = document.createElement("div");
      errorMessage.className = "error-message";
      errorMessage.textContent = e.message || "Failed to send emails. Please try again.";
      document.body.appendChild(errorMessage);

      setTimeout(() => {
        errorMessage.classList.add("fade-out");
        setTimeout(() => {
          document.body.removeChild(errorMessage);
        }, 500);
      }, 3000);
    } finally {
      setIsLoading(false); // Stop loading regardless of outcome
    }
  };

  const renderSentRanges = () => {
    if (!sentRanges.length) return null;
    
    return (
      <div className="sent-ranges-info">
        <h4>Previously Sent Ranges:</h4>
        {sentRanges.map((range, idx) => (
          <div key={idx} className="range-item">
            {`${range.range_start + 1}-${range.range_end + 1} (${range.date_sent})`}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div id="sending-page">
      <div className="page-header">
        <Dropdown
          data={scrapedDatasetNames}
          currentDatasetSelected={selectedOption}
          handleDropdownChange={handleDropdownChange}
        />
        {(alreadySentWarning || isDatasetComplete) && (
          <SentRangesWarning 
            overlappingRanges={overlappingRanges}
            isDatasetComplete={isDatasetComplete}
          />
        )}
      </div>

      <div className="send-emails-portion-container">
        <SendEmails
          setMessage={setMessage}
          setSubject={setSubject}
          subject={subject}
          message={message}
          appCode={appCode}
          setAppCode={setAppCode}
          handleSendEmails={handleSendEmails}
          isLoading={isLoading}
        />

        <div className="recipient-container">
          <div className="selected-recipients-list">
            <h3>Selected Recipients:</h3>
            {selectedOption !== "None" && (
              <RangeSelector
                totalCount={emailData.length}
                rangeStart={rangeStart}
                setRangeStart={setRangeStart}
                rangeEnd={rangeEnd}
                setRangeEnd={setRangeEnd}
              />
            )}
            <div className="recipients-list">
              {emailData
                .slice(rangeStart, rangeEnd)  // First slice to get range
                .map((value, index) => {
                  const originalIndex = index + rangeStart;  // Calculate original index
                  if (removedIndices.has(originalIndex)) {  // Then check if removed
                    return null;  // Skip removed items
                  }
                  return (
                    <ResultEntry
                      key={originalIndex}
                      name={value.name}
                      company={value.company}
                      profile_link={value.profile_link}
                      profile_img={value.profile_image}
                      onRemove={() => handleRemoveRecipient(originalIndex)}
                    />
                  );
                })
                .filter(Boolean)  // Remove null entries
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SendingPage;
