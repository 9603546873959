import React, { useState } from 'react';
import './css/RangeSelector.css';

const RangeSelector = ({ 
  totalCount, 
  rangeStart, 
  setRangeStart, 
  rangeEnd, 
  setRangeEnd,
  sentRanges 
}) => {
  const [inputStart, setInputStart] = useState(rangeStart + 1);
  const [inputEnd, setInputEnd] = useState(rangeEnd);

  const handleStartChange = (e) => {
    const value = e.target.value;
    const parsedValue = parseInt(value);

    // Allow empty value while typing
    if (value === '') {
      setInputStart('');
      return;
    }

    // Enforce rules for start index
    if (isNaN(parsedValue)) return;

    // Keep within valid range
    const validStart = Math.max(1, Math.min(parsedValue, Math.min(inputEnd, totalCount)));
    setInputStart(validStart);
    
    // Update internal state (0-based)
    setRangeStart(validStart - 1);
  };

  const handleEndChange = (e) => {
    const value = e.target.value;
    const parsedValue = parseInt(value);

    // Allow empty value while typing
    if (value === '') {
      setInputEnd('');
      return;
    }

    // Enforce rules for end index
    if (isNaN(parsedValue)) return;

    // Keep within valid range
    const validEnd = Math.max(inputStart, Math.min(parsedValue, totalCount));
    setInputEnd(validEnd);
    
    // Update internal state
    setRangeEnd(validEnd);
  };

  // Handle blur events to ensure valid values
  const handleStartBlur = () => {
    if (inputStart === '' || inputStart < 1) {
      setInputStart(1);
      setRangeStart(0);
    } else if (inputStart > inputEnd) {
      setInputStart(inputEnd);
      setRangeStart(inputEnd - 1);
    }
  };

  const handleEndBlur = () => {
    if (inputEnd === '' || inputEnd > totalCount) {
      setInputEnd(totalCount);
      setRangeEnd(totalCount);
    } else if (inputEnd < inputStart) {
      setInputEnd(inputStart);
      setRangeEnd(inputStart);
    }
  };

  // Update local input values when props change
  React.useEffect(() => {
    setInputStart(rangeStart + 1);
    setInputEnd(rangeEnd);
  }, [rangeStart, rangeEnd]);

  return (
    <div className="range-selector-container">
      <div className="range-inputs-container">
        <div className="range-input-group">
          <label>Start:</label>
          <input
            type="number"
            min="1"
            max={totalCount}
            value={inputStart}
            onChange={handleStartChange}
            onBlur={handleStartBlur}
          />
        </div>
        <div className="range-separator">to</div>
        <div className="range-input-group">
          <label>End:</label>
          <input
            type="number"
            min={inputStart}
            max={totalCount}
            value={inputEnd}
            onChange={handleEndChange}
            onBlur={handleEndBlur}
          />
        </div>
      </div>
      <div className="range-info">
        Selected: {rangeEnd - rangeStart} of {totalCount} recipients
      </div>
      
      {sentRanges?.length > 0 && (
        <div className="sent-ranges-info">
          <h4>Previously Sent:</h4>
          <div className="sent-ranges-list">
            {sentRanges.map((range, idx) => (
              <div key={idx} className="range-item">
                {`${range.range_start + 1}-${range.range_end + 1}`}
                <span className="range-date">{range.date_sent}</span>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default RangeSelector; 